import Vue from 'vue'
import axios from 'axios'
let API_BASE_URL = ''
function getQueryString (name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2]); return null
}
var userAgent = navigator.userAgent;
if (/toutiaomicroapp/i.test(userAgent)) {
  if (getQueryString('env')==='dev') {
    // 测试环境
    if (document.location.protocol === 'http:') {
      API_BASE_URL = 'http://test.wefanbot.com:18998/scrm/v1/dy-'
    } else {
      API_BASE_URL = 'https://test.wefanbot.com:28998/scrm/v1/dy-'
    }
  } else {
    // 生产环境
    if (document.location.protocol === 'http:') {
      API_BASE_URL = 'http://saasapi.wefango.com/scrm/v1/dy-'
    } else {
      API_BASE_URL = 'https://saasapi.wefango.com/scrm/v1/dy-'
    }
  }
 } else if (userAgent.indexOf('miniProgram') > -1) {
  if (getQueryString('env')==='dev') {
    // 测试环境
    if (document.location.protocol === 'http:') {
      API_BASE_URL = 'http://test.wefanbot.com:18998/scrm/v1/ks-'
    } else {
      API_BASE_URL = 'https://test.wefanbot.com:28998/scrm/v1/ks-'
    }
  } else {
    // 生产环境
    if (document.location.protocol === 'http:') {
      API_BASE_URL = 'http://saasapi.wefango.com/scrm/v1/ks-'
    } else {
      API_BASE_URL = 'https://saasapi.wefango.com/scrm/v1/ks-'
    }
  }
 } else {
  if (getQueryString('env')==='dev') {
    // 测试环境
    if (document.location.protocol === 'http:') {
      API_BASE_URL = 'http://test.wefanbot.com:18998/scrm/v1/dy-'
    } else {
      API_BASE_URL = 'https://test.wefanbot.com:28998/scrm/v1/dy-'
    }
  } else {
    // 生产环境
    if (document.location.protocol === 'http:') {
      API_BASE_URL = 'http://saasapi.wefango.com/scrm/v1/dy-'
    } else {
      API_BASE_URL = 'https://saasapi.wefango.com/scrm/v1/dy-'
    }
  }
}
//创建实例
const instance = axios.create({
    baseURL: API_BASE_URL,
    timeout: 5000
});
//  interceptors 拦截器
// 添加请求拦截器
instance.interceptors.request.use(config => {
  console.log(config)

    return config
},
    err => {
        return Promise.reject(err)
    }
)
// 添加响应拦截器
instance.interceptors.response.use(response => {
  let data = response.data || response
    if (data.code === 401) {//没权限
        Vue.prototype.$toast(response.data.message)
    }
    return Promise.resolve(data)
},
    err => {
        return Promise.reject(err)
    }
)
Vue.prototype.$http = instance
export default instance
